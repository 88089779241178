import {HTTP} from '../utils/axios'

class MemberManageModel extends HTTP {
  /*首页渲染*/
  index(key="", phone_number="", email="", is_vip="", page = 1) {
      return this.request({
          url: 'board/user_manages',
          method: "GET",
          data: {
            key: key,
            phone_number: phone_number,
            email: email,
            is_vip: is_vip,
            page: page
          }
      })
  }

  /*时间搜索*/
  time(date1 = '', date2 = '') {
    return this.request({
        url: 'board/user_manages',
        method: "GET",
        data: {
          date1: date1,
          date2: date2
        }
    })
  }

  /*修改*/
  update(value, url) {
    return this.request({
        url: url,
        method: "PATCH",
        data: value,
    })
  }

    /*修改*/
    updateBalance(value, url) {
        return this.request({
            url: url,
            method: "PUT",
            data: value,
        })
    }
}

export {MemberManageModel}