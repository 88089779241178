<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入账号或昵称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="['phone_number']"
          placeholder="请输入手机号"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="['email']"
          placeholder="请输入邮箱"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-select
                v-decorator="['is_vip']"
                class="sm-select"
                placeholder="会员类型"
              >
                <a-select-option value="t"> VIP 会员 </a-select-option>
                <a-select-option value="f"> 普通会员 </a-select-option>
              </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          清空
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      class="sm-mt-20"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: true }"
    >
      <template slot="is_activity" slot-scope="text, record">
        <a-switch :default-checked="text" @change="onChange(record)" />
      </template>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleChangePasswordById(record)">
          修改密码
        </a>
        <a href="javascript:;" @click="handleChangeBalanceById(record)">
          修改积分
        </a>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
    <a-modal
      title="修改密码"
      v-model="changePasswordVisible"
      @ok="handleChangePasswordOk"
      @cancel="handleChangePasswordCancel"
      okText="确认"
      cancelText="取消"
      width="450px"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="新密码">
          <a-input
            v-decorator="[
              'password',
              { rules: [{ required: true, message: '请输入新密码!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="确认新密码">
          <a-input
            v-decorator="[
              'confirm_password',
              { rules: [{ required: true, message: '请确认新密码!' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      title="修改积分"
      v-model="changeBalanceVisible"
      @ok="handleChangeBalanceOk"
      @cancel="handleChangeBalanceCancel"
      okText="确认"
      cancelText="取消"
      width="450px"
    >
      <a-form
        :form="form3"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="客户账号">
          <a-input v-decorator="['user_name']" disabled />
        </a-form-item>
        <a-form-item label="客户昵称">
          <a-input v-decorator="['nick_name']" disabled />
        </a-form-item>
        <a-form-item label="修改前积分">
          <a-input v-decorator="['balance']" disabled />
        </a-form-item>
        <a-form-item label="积分调整">
          <a-input-number
            style="width: 100%"
            v-decorator="[
              'balance_adjustment',
              { rules: [{ required: true, message: '请输入调整积分!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-input
            v-decorator="[
              'remark',
              { rules: [{ required: true, message: '请输入备注!' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { MemberManageModel } from "../../models/memberManage";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";

import { mapMutations } from "vuex";

const memberManageModel = new MemberManageModel();
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "账号",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "昵称",
    dataIndex: "nick_name",
    key: "nick_name",
  },
  {
    title: "绑定手机",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "绑定邮箱",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "账户积分",
    dataIndex: "balance",
    key: "balance",
  },
  {
    title: "会员类型",
    dataIndex: "is_vip_display",
    key: "is_vip_display",
  },
  {
    title: "VIP 会员到期时间",
    dataIndex: "vip_end_time",
    key: "vip_end_time",
  },
  {
    title: "登陆次数",
    dataIndex: "sign_in_count",
    key: "sign_in_count",
  },
  {
    title: "下载次数",
    dataIndex: "download_num",
    key: "download_num",
  },
  {
    title: "最近登录时间",
    dataIndex: "current_sign_in_at",
    key: "current_sign_in_at",
    scopedSlots: { customRender: "current_sign_in_at" },
  },
  {
    title: "注册时间",
    dataIndex: "created_at",
    key: "created_at",
    scopedSlots: { customRender: "created_at" },
  },
  {
    title: "是否有效",
    dataIndex: "is_activity",
    key: "is_activity",
    scopedSlots: { customRender: "is_activity" },
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, { name: "horizontal_login" }),
      data: [],
      id: 0,
      columns,
      // 修改密码
      changePasswordVisible: false,
      // 修改积分
      changeBalanceVisible: false,
      loading: false,
      total: 0,
      current: 1,
      form2: this.$form.createForm(this, { name: "coordinated" }),
      form3: this.$form.createForm(this, { name: "change_balance" }),
    };
  },

  created() {
    this.queryUsersList();
  },

  methods: {
    onChange(record) {
      let url = "board/user_manages/" + record.id;
      memberManageModel
        .update({ is_activity: !record.is_activity }, url)
        .then((res) => {
          this.$notification.open({
            message: "提示",
            description: res.message,
            duration: 2,
          });
          this.queryUsersList();
        });
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          let email = values.email ? values.email.trim() : "";
          let phone_number = values.phone_number
            ? values.phone_number.trim()
            : "";
          let is_vip = values.is_vip == "t" ? true : false;
          this.queryUsersList(key, phone_number, email, is_vip, 1);
        }
      });
    },

    handleClear() {
      this.queryUsersList();
      this.form1.resetFields();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryUsersList(
            values.key,
            values.phone_number,
            values.email,
            values.is_vip,
            page
          );
        }
      });
    },

    handleChangePasswordById(record) {
      this.changePasswordVisible = true;
      this.id = record.id;
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          user_name: record.user_name,
          nick_name: record.nick_name,
          email: record.email,
        });
      });
    },

    handleChangeBalanceById(record) {
      this.changeBalanceVisible = true;
      this.id = record.id;
      this.$nextTick(() => {
        this.form3.setFieldsValue({
          user_name: record.user_name,
          nick_name: record.nick_name,
          balance: record.balance,
        });
      });
    },

    handleChangePasswordOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          let url = "board/user_manages/" + this.id;
          if (values.password.trim() == values.confirm_password.trim()) {
            memberManageModel.update(values, url).then((res) => {
              if (res.code == 0) {
                this.changePasswordVisible = false;
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.queryUsersList();
              }
              this.form2.resetFields();
            });
          } else {
            this.$notification.open({
              message: "提示",
              description: "两次密码输入不一致",
              duration: 2,
            });
          }
        }
      });
    },

    handleChangePasswordCancel() {
      this.form2.resetFields();
    },

    handleChangeBalanceOk(e) {
      e.preventDefault();
      this.form3.validateFields((err, values) => {
        if (!err) {
          let url = "board/user_manages/update_balance";
          values.id = this.id;
          memberManageModel.updateBalance(values, url).then((res) => {
            if (res.code == 0) {
              this.changeBalanceVisible = false;
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryUsersList();
            }
            this.form3.resetFields();
          });
        }
      });
    },

    handleChangeBalanceCancel() {
      this.form3.resetFields();
    },

    queryUsersList(key, phoneNumber, email, is_vip, page) {
      this.loading = true;
      memberManageModel.index(key, phoneNumber, email, is_vip, page).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            if (item.is_vip) {
              item.vip_end_time = formatTime(item.vip_end_time);
            } else {
              item.vip_end_time = "";
            }
            item.current_sign_in_at =
              item.sign_in_count > 0 ? formatTime(item.current_sign_in_at) : "";
            if (item.online_time < 60) {
              item.online_time = item.online_time + "s";
            } else if (item.online_time >= 60 && item.online_time < 3600) {
              item.online_time = (item.online_time / 60).toFixed(1) + "m";
            } else {
              item.online_time = (item.online_time / 3600).toFixed(1) + "h";
            }
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss">
</style>
